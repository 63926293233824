.circle {
    width: 29px;
    height: 29px;
    line-height: 25px;
    background-color: rgb(255, 255, 255);
    color: #D4171B;
    border-radius: 50%;
    border: 2px solid #D4171B;
    text-align: center;
    cursor: pointer;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  
  .stepWrapper {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  .stepBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .stepBlock .circleWrapper {
    padding: 0px 20px;
    position: relative;
  }
  
  .selected .circle {
    color: black;
    background-color: #D4171B;
    color: #fff;
    border-color: #D4171B;
  }

  span{
    margin: 10px;
  }
  
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background-color: green;
    margin: auto;
    z-index: -1;
  }
  
  .stepBlock:not(:last-child) > .circleWrapper::after {
    height: 3px;
  }
  
  .stepBlock:not(:last-child).selected > .circleWrapper::after, .stepBlock:not(:last-child).selected ~ .stepBlock:not(:last-child) > .circleWrapper::after {
    height: 2px;
    opacity: 0.6;
  }
  
  .primaryButton {
    padding: 10px 25px;
    background-color: #D4171B;
    border: none;
    margin:0px 10px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .primaryButton:disabled {
    opacity: 0.5;
  }

  @media only screen and (max-width:1024px){
    
    .stepWrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      justify-content: space-between;
    }
    
}