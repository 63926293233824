.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
aside-active
.dialog-content {
  position: relative;
  display:flex;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 10px;
  left: -20px;
  transform: translate(50%, -50%); 
  cursor: pointer;
}
.titre {
  padding-left: 100px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  outline: none;
  border: none;
  text-decoration: none;
  color: #060e30;
  letter-spacing: 0.5%;
}

.AddPatient {
  overflow-x: hidden;
  height: fit-content;
}

input,
select {
  border-radius: 10px;
  background-color: #dadada;
  padding-left: 2%;
  font-size: 18px;
  height: 47px;
}

textarea {
  padding-left: 1%;
  font-size: 18px;
}

.home {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

nav {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: relative;
  border-bottom: 1px solid #e1e1e1;
}

.image img{
  width: auto;
  height: 120px;
}
.menu {
  display: none;
  flex-direction: column;
  gap: 2px;
  margin-right: 25px;
  cursor: pointer;
}

.line {
  width: 1.5rem;
  height: 4px;
  background-color: black;
  border-radius: 20px;
}

.left-nav {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 32px;
}

.left-nav .login- {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: gray;
}

.left-nav .logo {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 3px;
  font-family: "Montserrat-Alt1 Light", sans-serif;
  background: linear-gradient(
    to right,
    #71c5ee,
    #53a7d8,
    #3889c1,
    #1e6caa,
    #025091
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.right-nav {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
  padding-right: 70px;
}

.aide{
  display: flex;
  flex-direction: Row ;
  align-items: center;
  gap: 10px;
  background-color: #DA171B;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.aide h1{
  color: white;
}

/*
.doctor-logo {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 229px;
}
*/

.right-nav .doctor {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 250px;
}

.right-nav .doctor .doctor-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: grey;
}

.right-nav .doctor .doctor-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.right-nav .doctor .doctor-name h4 {
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.right-nav .doctor .doctor-name span {
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
  color: black;
}

.lv {
  width: 1px;
  height: calc(100vh - 80px);
  background-color: #e1e1e1;
  position: absolute;
  top: 80px;
  left: 80px;
  z-index: 1;
}

.aside-active {
  width: 100vw;
  height: calc(100vh - 90px);
  position: absolute;
  bottom: 0;
  top: 85px;
  background-color: white;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transform: translateX(-150%);
  transform: translateX(-150%);
  transition: all 1s ease;
  z-index: 98;
}

.aside-active.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.aside-active ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.aside-active li {
  position: relative;
  cursor: pointer;
}

.aside-active li::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #71c5ee;
  border-radius: 1000px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  transform-origin: right;
  transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
}

.aside-active li:hover::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.aside-active ul li a {
  display: flex;
  align-items: center;
  gap: 30px;
  text-transform: capitalize;
  font-size: 22px;
}

.aside {
  position: absolute;
  top: 85px;
  bottom: 10px;
  left: 10px;
  width: 60px;
  overflow: hidden;
  transition: width 0.2 linear;
  padding-left: 9px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 99;
  height: calc(100vh - 90px);
}

.aside ul {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 30px;
  list-style: none;
}
.aside ul li {
  align-items: center;
  display: flex;
}

.aside ul li .link {
  display: flex;
  align-items: center;
  color: black;
  font-size: 18px;
  width: 200px;
  text-transform: capitalize;
}

.aside ul li:nth-child(10){
  position: absolute;
  bottom: 50px; 
}

.aside ul li:last-child {
  position: absolute;
  bottom: 20px;
}

.aside ul li:hover {
  
  margin-left: 5px;
  background-color: #f69597;
  border-radius: 10px 0 0 10px;
  transition: all 1s ease;
  padding-left: 20px;
}

.aside-item-active {
  height: auto;
  width: 20px;
  padding: 5px;
  border-radius: 5px;
  background-color:#f69597;
}



/*.list-active{
  line-height: 50px;
  background-color: #71c5ee;
  border-radius: 20px 0 0 20px;
  width: 60px;
}*/


.asidebar-Hiden,.navbar-hiden{
display: none;
opacity: 0;
}



.aside:hover {
  width: 240px;
  transition: all 1s ease;
}


.title {
  margin-left: 20px;
}




.panne-list-container{
  width: calc(100% - 82px);
  height: calc(100vh - 80px);
  position: absolute;
  background-color: #f8f9fd;
  left: 82px;
  top: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}



.add-visite-active .visite-table-container {
  margin-top: 12vh;
}

.add-vaccin-active2 .vaccin-container {
  margin-top: 5vh;
}

.concultation-table {
  display: none;
}

.add-vaccin-active,
.add-visite-active,
.add-visite-active2,
.add-vaccin-active2,
.add-concultation-active {
  display: flex;
  transition: all 2s ease;
}

.add-vaccin-active .add-vaccin-button {
  width: 969px;
  -webkit-transform: translateX(55%);
  transform: translateX(55%);
  margin-top: -5vh;
  transition: all 2s ease;
}

.add-visite-active2 .add-visite-button {
  width: 969px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 2s ease;
}

.add-visite-active {
  margin-top: -20px;
}

.add-vaccin-active .vaccin-container {
  margin-top: 12vh;
  margin-bottom: 0;
}

.add-visite-active2,
.add-vaccin-active2 {
  margin-top: 10px;
}

.add-vaccin-active2 {
  margin-top: 40px;
}

.patient-details .cahier-formulaire-swiper {
  display: none;
}

.patient-details .cahier-vaccin {
  position: relative;
}

.patient-details .add-cahier-active .cahier-formulaire-swiper {
  position: absolute;
  top: -3vh;
  transition: all 1s ease-in-out;
  display: flex;
}

.patient-details .add-cahier-active .lock-icon {
  opacity: 0;
}

.vaccin-visite-class.add-vaccin-active .add-visite-button,
.vaccin-visite-class.add-visite-active2 .add-vaccin-button {
  opacity: 0;
}

.vaccin-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.vaccin-table-container,
.visite-table-container {
  width: 85%;
  padding: 0 10px;
  box-shadow: 0 0 0px;
  display: flex;
  flex-direction: column;
  gap: 29px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}


table td span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

table {
  border-collapse: collapse;
}

.header-table {
  width: 300px;
}

table td input {
  width: 50px;
}

table td {
  text-align: center;
  border: 1px solid black;
}

@media screen and (max-width: 768px) {
  .home {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
  }

  .left-nav {
    padding-left: 15px;
  }

  .right-nav {
    padding-right: 0px;
    gap: 20px;
  }

  .home-formulaire-swiper {
    height: 75%;
  }

  .right-nav .doctor {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .aside {
    display: none;
  }

  .title {
    margin-left: 30px;
  }

  .menu {
    display: flex;
    margin-right: 5px;
  }

  .aside-active {
    display: flex;
  }

  .home-container {
    width: 100vw;
    margin-left: 0;
  }

  .top-titre {
    margin-top: 10px;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }

  .top-right {
    gap: 10px;
    align-items: flex-start;
    padding-left: 5%;
    flex-direction: column;
    padding-right: 0;
  }

  .formulaire-left {
    width: 100%;
  }

  .formulaire-centre,
  .formulaire-right {
    display: none;
  }

  .vl {
    display: none;
  }

  .sexe-date-heure,
  .date-poids-taille,
  .pre-fre,
  .app-cardio,
  .pleuro-pul,
  .conj-eryth-globe,
  .depistage,
  .malformations,
  .examen-de-la-peau,
  .traumatismes,
  .examen-neuro {
    flex-direction: column;
    padding-left: 0px;
    margin-top: 22px;
  }

  .conclusion h2,
  .succion,
  .signe-moro {
    padding-left: 0;
  }

  .examen-neuro,
  .examen-de-la-peau,
  .app-cardio,
  .pleuro-pul,
  .malforamtion,
  .traumatismes,
  .conclusion,
  .fente,
  .bec,
  .hanches,
  .imperformation,
  .yeux,
  .conjonctivite,
  .erytheme,
  .globe {
    padding-right: 10px;
    padding-left: 10px;
  }

  .autre {
    padding-left: 10px;
  }

  .depistage {
    padding-right: 10px;
  }

  .reflexes {
    margin-left: 0;
    padding-left: 0;
  }

  .reflexes span {
    margin-left: 0;
  }

  .pre-fre {
    margin-top: 40px;
  }

  .score-dapgar {
    gap: 0px;
    padding-left: 0;
  }

  .minute-1,
  .minute-5 {
    margin-top: 20px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .minute-1 {
    margin-left: 20px;
  }

  .minute-5 {
    margin-right: 20px;
  }

  .score {
    margin-left: 20px;
  }

  .rea-duree-malf,
  .transfert-motif {
    flex-direction: column;
    gap: 25px;
  }

  .reanimation span {
    margin-right: 20px;
  }

  .transfert span {
    margin-left: 90px;
  }

  .transfert input {
    margin-left: 20px;
  }

  .duree span {
    margin-right: 38px;
    margin-left: 40px;
  }

  .motif {
    gap: 0px;
  }

  .motif span {
    width: 200px;
    margin-left: 60px;
  }

  .motif input {
    width: 244px;
  }

  .cordon,
  .choanes,
  .atresie,
  .genitaux,
  .k1,
  .c-choanes {
    flex-direction: column;
  }

  .examen,
  .cathet,
  .organes,
  .vitamine,
  .catheterisme,
  .recherche {
    justify-content: center;
    padding-left: 0;
  }

  .recherche {
    padding-left: 20px;
  }

  .cordon span,
  .choanes span,
  .genitaux span,
  .k1 span,
  .c-choanes span {
    margin-left: 40px;
  }

  .atresie {
    gap: 10px;
  }

  .cordon input,
  .choanes input,
  .atresie input,
  .genitaux input,
  .k1 input,
  .c-choanes input {
    width: 400px;
  }

  .emission {
    gap: 0;
  }

  .urine,
  .meconium {
    gap: 0;
  }

  .urine span,
  .meconium span {
    margin-left: 30px;
  }

  .urine input,
  .meconium input {
    margin-right: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
}

@media screen and (min-width: 1024px) and (max-width: 1600px) {
}

@media screen and (min-width: 1600px) {
  .top-titre h2 {
    padding-top: 43px;
  }
}

body::-webkit-scrollbar {
  display: flex;
  width: 10px;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #4f81ff;
  border-radius: 10px;
}

.calendrier-container {
  padding-left: 100px;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #4f81ff;
  overflow: hidden;
}

.container:before {
  content: "";
  position: absolute;
  width: 2000px;
  height: 2000px;
  background: linear-gradient(-45deg, #fff, #fff);
  top: -10px;
  right: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 6;
  transition: 1.8s ease-in-out;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5rem;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  transition: 0.2s 0.7s ease-in-out;
}






.dmnd-btn {
  height: 43px;
  margin-top: 14px;
  background-color: rgba(6, 14, 48, 0.5);
}

.panels-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  height: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel {
  display: flex;
  flex-direction: column;
  z-index: 7;
}

.left-panel {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: all;
}

.right-panel {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}

.panel .content {
  transition: 0.9s 0.6s ease-in-out;
}

.sign-in-form {
  display: flex;
  flex-direction: column;
  gap: 17px;
  background-color: rgba(6, 14, 48, 0.7);
  height: 100vh;
}

.image-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 1.1s 0.4s ease-in-out;
  height: 90vh;
  z-index: 9;
  background-color: #fff;
}

.demande-accee,
.demande-accee2 {
  width: 392px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgba(6, 14, 48, 0.5);
  border: none;
  text-transform: capitalize;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.demande-accee2 a {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.demande-accee {
  position: absolute;
  right: 11.75rem;
  top: 37rem;
}

.demande-accee2 {
  width: 362px;
  background-color: rgba(79, 129, 255, 1);
  margin-top: 20px;
}

.demande-accee:hover,
.demande-accee2:hover {
  background-color: rgba(6, 14, 48, 1);
  transition: all 1s ease;
  color: #fff;
}

.back-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  cursor: pointer;
  position: absolute;
  top: -16.5rem;
  left: -45rem;
  display: none;
}

.sign-in-form .back-btn i {
  color: rgba(6, 14, 48, 1);
}

.left-panel .content .logo,
.right-panel .content .logo {
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 3px;
  font-family: "Montserrat-Alt1 Light", sans-serif;
  background: linear-gradient(
    to right,
    #71c5ee,
    #53a7d8,
    #3889c1,
    #1e6caa,
    #025091
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.left-panel .content,
.right-panel .content {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-panel .content {
  margin-top: 60px;
}

.right-panel .content {
  gap: 28px;
}

.right-panel p {
  width: 476px;
  height: 72px;
  text-align: center;
  font-weight: 700;
  line-height: 24px;
  font-size: 20px;
  color: rgba(6, 14, 48, 1);
}

.left-panel .image-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-panel .image-login img {
  width: 675px;
  height: 558px;
}

.right-panel .content,
.right-panel .image-login {
  -webkit-transform: translateX(800px);
  transform: translateX(800px);
}

.sign-in-mode #demande-accee {
  display: none;
}

.sign-in-mode #back-btn {
  display: flex;
}

.sign-in-mode::before {
  -webkit-transform: translateX(100%, -50%);
  transform: translateX(100%, -50%);
  right: -80%;
}

.sign-in-mode .left-panel .image-login,
.sign-in-mode .left-panel .content {
  -webkit-transform: translateX(-800px);
  transform: translateX(-800px);
}

.sign-in-mode .right-panel .image-login,
.sign-in-mode .right-panel .content {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.sign-in-mode .left-panel {
  pointer-events: none;
}

.sign-in-mode .right-panel {
  pointer-events: all;
}

.sign-in-mode .login-signin {
  left: 25%;
}

.sign-in-mode form.login-in-form {
  z-index: 1;
  opacity: 0;
}

.sign-in-mode form.sign-in-form {
  z-index: 2;
  opacity: 1;
}

#demande-accee {
  z-index: 1;
}

/* dashboard style */

.dashboard-container {
  width: calc(100% - 82px);
  height: calc(100vh - 80px);
  position: absolute;
  background-color: #f8f9fd;
  left: 82px;
  top: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.dashboard-top {
  width: 100%;
  height: 102px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 28px;
}

.dashboard-item {
  width: 292px;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px;
}

.dashboard-item-title,
.dashboard-nombre {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-item-title a,
.dashboard-nombre h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  cursor: pointer;
}

.dashboard-item-title a:hover,
.dashboard-nombre h3:hover,
.chart-header a:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.dashboard-pourcentage-bad {
  color: red;
}

.dashboard-pourcentage-good {
  color: green;
}

.dashboard-item-title a span {
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 700;
}

.dashboard-charts-calnedar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-left: 31px;
  padding-right: 2.5%;
}

.dashboard-chart-container,
.dashboard-sexe-chart-container {
  width: 70%;
  padding-left: 10px;
  padding-right: 10px;
  height: 680px;
  border-radius: 8px;
  background-color: #fff;
}

.dashboard-sexe-chart-container {
  width: 95%;
  height: fit-content;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-dashboard1 {
  width: 880px;
  border-radius: 8px;
}

.dashboard-calendar-container {
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  height: 680px;
}

.chart-header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 34px;
}

.calendar-dashboard-header {
  width: 100%;
  height: 80px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.calendar-dashboard-header h2,
.upcomping h2 {
  font-size: 21px;
  font-weight: 500;
  line-height: 31px;
  color: #000;
}

.dashboard-calendar-lh {
  width: 100%;
  background-color: rgba(210, 210, 210, 1);
  height: 0.8px;
  margin-top: 5px;
}

.dashboard-calendar-bottom {
  margin-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upcomping {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
}

.upcomping a {
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #1a71ff;
  text-transform: capitalize;
  padding-top: 5px;
}


.upcomping a:hover {
  text-decoration: underline;
}

.dashboard-calendar-doctor-meet {
  display: flex;
  align-items: center;
  background-color: #da171a29;
  border-radius: 10px;
  padding-left: 12px;
  height: 63px;
  margin-bottom: 10px;
}

.dashboard-calendar-doctor-meet h2{
  margin-left: 10px;

}


.h-96{
  margin-top: 10px;
  height: max-content;
}

.monthly-doctor {
  width: 47px;
  height: 47px;
  background: linear-gradient(
    66.15deg,
    #57b4de -5.25%,
    #58b6dd 35.43%,
    #3b29ab 98.77%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.monthly-doctor span {
  font-size: 16px;
  font-weight: 700;
  line-height: 24.5px;
  color: #fff;
}

.monthly-meet a {
  font-weight: 500;
  color: #23262f;
  font-size: 16px;
  line-height: 24px;
}

.monthly-meet h3 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  cursor: pointer;
  color: rgba(13, 13, 13, 0.6);
}

.monthly-meet h3:hover {
  text-decoration: underline;
}

.chart-line-container {
  width: 95%;
  height: 1px;
  background-color: rgba(79, 129, 255, 0.42);
}
.chart-line {
  width: 100%;
  display: flex;
  justify-content: center;
}
.select-chart {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 34px;
}

.displaying-data-chart{
  width: 169px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #DA171B;
  color: #DA171B;
  background-color: transparent;
  cursor: pointer;
  padding: 0px;
}
.select-data-chart {
  width: auto;
  height: 40px;
  border-radius: 8px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #DA171B;
  background-color: transparent;
  cursor: pointer;
  padding: 0px;
  font-weight: 600;
}

.table-right-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 80px;
  width: 100px;
}

.table-right-lv {
  width: 1px;
  height: 47px;
  background-color: rgba(189, 189, 189, 1);
}

.table-right-item h3 {
  font-weight: 500;
  position: absolute;
  top: 5px;
  font-size: 24px;
  color: rgba(6, 14, 48, 1);
}
.table-right-item span {
  font-weight: 400;
  position: absolute;
  top: 40px;
  font-size: 16px;
  color: rgba(189, 189, 189, 1);
}

.table-patients {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.table-patients {
  height: 74vh;
}

.table-traitement-nom input {
  width: 31px;
  height: 29px;
  border-radius: 10px;
  margin-right: 10px;
}

.title-table-traitement-nom,
.title-table-traitement-date,
.title-table-traitement-status,
.title-table-traitement-progres,
.title-table-traitement-cas {
  font-weight: 500;
  font-size: 18px;
  line-height: 21.6px;
  color: rgba(189, 189, 189, 1);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-traitement-nom,
.table-traitement-date,
.table-traitement-status,
.table-traitement-progres,
.table-traitement-cas {
  font-weight: 500;
  font-size: 18px;
  line-height: 21.6px;
  color: rgba(6, 14, 48, 1);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-traitement-date {
  flex-direction: column;
  font-size: 16px;
  line-height: 19.2px;
  color: rgba(6, 14, 48, 1);
}

.table-traitement-date span {
  font-size: 12px;
  line-height: 14.4px;
  color: rgba(106, 110, 131, 1);
}

.table-status-item {
  width: 107px;
  height: 27px;
  background-color: rgba(79, 129, 255, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-status-item.termine {
  background-color: #6cab7626;
}

.table-status-item.encours span,
.table-status-item.termine span {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  color: rgba(79, 129, 255, 1);
}

.table-status-item.termine span {
  color: #6cab76;
}

.table-traitement-progres {
  display: flex;
  gap: 5px;
}

.table-progres-item {
  height: 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4f81ff80;
  padding-left: 10px;
  padding-right: 10px;
}

.table-progres-item span {
  font-weight: 500;
  font-size: 14px;
  line-height: 9.6px;
  color: #060e30;
}

.bleu {
  background-color: #4f81ff80;
}

.bleu span {
  color: #fff;
}

.yellow {
  background-color: rgba(250, 255, 0, 0.5);
}

.green {
  background-color: rgba(108, 171, 118, 0.15);
}

.green span {
  color: rgba(108, 171, 118, 1);
}

.gray {
  background-color: rgba(225, 225, 225, 1);
}

.gray span {
  color: rgba(174, 174, 174, 1);
}

.title-table-traitement-status,
.table-traitement-status {
  justify-content: start;
  padding-left: 40px;
}

.title-table-traitement-status {
  padding-left: 65px;
}

/* patient details style */

.patient-table,
.Malade-Profile {
  width: 100%;
  position: relative;
}

.patient-table-container,
.Malade-profile-container {
  width: calc(100% - 80px);
  position: absolute;
  left: 82px;
  display: flex;
  flex-direction: column;
}

.patient-table-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.table-header-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table-header-item label {
  font-weight: 700;
  font-size: 12px;
  line-height: 14.4px;
  color: rgba(106, 110, 131, 1);
  padding-left: 5px;
}

.table-header-item select {
  width: 242px;
  height: 47px;
  border-radius: 10px;
  border: 1px solid rgba(6, 14, 48, 1);
  background-color: transparent;
}

.table-patients {
  width: 100%;
  margin-top: 20px;
}

.table-patients table {
  width: 100%;
}

.table-enfant {
  width: 90%;
  height: fit-content;
  margin-bottom: 20px;
}

.table-patients table tr {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #DA171B;
}
.table-nouveau-ne-ligne:nth-child(2n),
.Maman-profile .table-concultation-ligne:nth-child(2n) {
  background-color: rgba(218, 218, 218, 1);
}
.table-nouveau-ne-ligne-red-row{
  background-color: #cA171B;
  .table-patients-td-nom{
    color: white;
  }
  .table-patients-td-id{
    color: white;
  }
  .table-patients-td-phone{
    color: white;
  }
  .table-patients-td-annee{
    color: white;
  }
  .table-patients-td-willaya{
    color: white;
  }
  .table-patients-td-region{
    color: white;
  }
}

.table-nouveau-ne-ligne-orange-row{
  background-color: rgba(255, 123, 0, 0.426);
}
.table-nouveau-ne-ligne-yellow-row{
  background-color: rgba(255, 251, 0, 0.426)
}
.table-nouveau-ne-ligne-white-row{
  background-color: #fff;
}
.table-patients td {
  border: none;
}

.table-patients-header-nom,
.table-patients-header-id,
.table-patients-header-phone,
.table-patients-header-Email,
.table-patients-td {
  font-weight: 700;
  font-size: 12px;
  line-height: 14.4px;
  color: rgba(106, 110, 131, 1);
  height: 50px;
}

.table-patients-td {
  color: rgba(6, 14, 48, 1);
}

.table-nouveau-ne-ligne {
  width: 100vw;
}

.table-patients-header-nom,
.table-patients-td-nom {
  width: 15%;
}
.table-patients-header-id,
.table-patients-td-id {
  width: 8%;
}

.table-patients-header-phone,
.table-patients-td-phone {
  width: 10%;
}

.table-patients-header-Email {
  width: 20%;
}

.table-patient-td-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 28px;
  padding-right: 28px;
}


.patient-details {
  width: 100%;
  height: 200vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.patient-details-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #060e30;
  margin-top: 26px;
  padding-left: 30px;
  padding-right: 55px;
}

.icon-button {
  cursor: pointer;
  font-size: 25px;
  color: rgba(6, 14, 48, 1);
}

.icon-button:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 1s ease;
}

.patient-details-header-right {
  display: flex;
  gap: 21px;
}

.patient-details h2 {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  color: #060e30;
  margin-top: 31px;
}

.patient-details-container {
  display: flex;
  width: 100%;
  margin-top: 25px;
  gap: 27px;
  padding-left: 20px;
  padding-right: 20px;
}

.patient-details-container-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 15px;
}

.about-patient,
.patient-details-center,
.patient-details-examen,
.patient-details-reducation,
.about-maman {
  width: 299px;
  height: 797px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 20px;
}

.patient-details-center {
  width: 864px;
}

.patient-details-examen {
  height: 413px;
}

.patient-details-reducation {
  height: 367px;
}

.about-patients-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.about-patient-image {
  width: 150px;
  height: 150px;
  border-radius: 41px;
  background-color: #d9d9d9;
  margin-top: 25px;
  margin-bottom: 25px;
}
.about-patient-cart {
  width: 100%;
  margin-top: 15px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.patient-cart-item {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.patient-cart-item span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: rgba(106, 110, 131, 1);
}

.patient-cart-item h3 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(6, 14, 48, 1);
}

.patient-details-examen h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: rgba(189, 189, 189, 1);
}

.patient-details-examen-container,
.patient-details-reducation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 25px;
}

.patient-details-examen-item,
.patient-details-examen-score,
.patient-details-examen-alliaitment,
.patient-details-reducation-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 30px;
}

.patient-details-examen-score,
.patient-details-examen-alliaitment {
  flex-direction: column;
  align-items: start;
}

.patient-details-examen-alliaitment {
  margin-top: 13px;
}

.patient-details-examen-item h3,
.patient-details-examen-score h3,
.patient-details-examen-alliaitment h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 16.8px;
  color: rgba(79, 129, 255, 1);
}

.patient-details-examen-item span {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(79, 129, 255, 1);
}

.patient-details-examen-score-inputs,
.patient-details-examen-alli-inputs {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 38px;
  margin-top: 10px;
}

.patient-details-examen-score-input,
.patient-details-examen-alli-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.patient-details-examen-score-input span,
.patient-details-examen-alli-input span {
  font-weight: 400;
  font-size: 10px;
  line-height: 9.6px;
  color: rgba(79, 129, 255, 1);
}

.patient-details-examen-score-input input,
.patient-details-examen-alli-input input {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.reducation-doctor {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.reducation-doctor-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 14.4px;
  color: rgba(79, 129, 255, 1);
}

.reducation-doctor span {
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: rgba(189, 189, 189, 1);
}

.patient-details-reducation-item a {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: rgba(79, 129, 255, 1);
}

.voir-reducation:hover {
  transition: all 1s ease;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.reducation-doctor-name:hover {
  text-decoration: underline;
}

.table-button {
  width: 80px;
  font-size: 10px;
}

.patient-details-dossier-obse,
.patient-details-cahier-vaccin {
  width: 100%;
  padding: 30px;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.patient-details-dossier-obse-swiper,
.patient-details .cahier-formulaire-swiper {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 1310px;
  margin-bottom: 30px;
}

.patient-details .cahier-formulaire-swiper {
  height: 1170px;
}

.profile-details-add-cahier {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15vh;
  height: 30vh;
}

/*patient details swiper style */

/*patient details dossier swiper */

.patient-details .pro-acc h2 {
  padding-left: 0;
  margin-left: -33%;
}

.patient-details .examen-annexes h2,
.patient-details .obser-entree h2 {
  margin-left: -35.5%;
  padding-left: 0;
}

.patient-details .resume-obser h2 {
  padding-left: 0;
  margin-left: -34.5%;
}

.patient-details .traumatismes h2 {
  padding-left: 0;
  margin-left: -35.6%;
}

.patient-details .diag-sor h2 {
  margin-left: -36.5%;
  padding-left: 0;
}

.patient-details .app-cardio h2 {
  margin-left: -36%;
  padding-left: 0;
}

.patient-details .pleuro-pul h2 {
  margin-left: -35.6%;
  padding-left: 0;
}

.patient-details .antecedents h2,
.patient-details .examen-de-la-peau h2,
.patient-details .conclusion h2,
.patient-details .partogramme h2 {
  padding-left: 0;
  margin-left: -39.1%;
}

.patient-details .malformations h2 {
  margin-left: -41.4%;
  padding-left: 0;
}

.patient-details .examen-entree h2 {
  padding-left: 0;
  margin-left: -37.5%;
}

.patient-details .examen-neuro h2 {
  padding-left: 0;
  margin-left: -38%;
}

.patient-details .date-acc input,
.patient-details .heure-acc input {
  width: 356px;
}

.patient-details .diag-sor textarea,
.patient-details .resume-obser textarea,
.patient-details .motif-hosp textarea,
.patient-details .psychologiques .textarea textarea,
.patient-details .examen-comple .textarea textarea,
.patient-details .pathologique .textarea textarea,
.patient-details .familiaux .textarea textarea,
.patient-details .empre-digi .textarea textarea,
.patient-details .accoucheur .textarea textarea,
.patient-details .re-annexe-textarea .textarea textarea,
.patient-details .examen-general .textarea textarea,
.patient-details .examen-obstetrical .textarea textarea {
  width: 1160px;
}

.patient-details .examen-annexes .annexe-item {
  padding-left: 3%;
}

.patient-details .psychologiques .pyscho-item,
.patient-details .examen-bio-item,
.patient-details .pyscho-item {
  padding-left: 5.8%;
}

.patient-details .examen-annexes .annexe input,
.patient-details .malforamtion-annexe input,
.patient-details .remarque-annexe input,
.patient-details .add-adm-vit-item input,
.patient-details .psychologiques .pyscho-item input,
.patient-details .pyscho-item input,
.patient-details .examen-bio-item input {
  width: 869px;
}

.patient-details .examen-entree-item2 input {
  width: 880px;
}

.patient-details .glycemie input,
.patient-details .uree input,
.patient-details .hu input,
.patient-details .constraction input,
.patient-details .presentation input {
  width: 392px;
}

.patient-details .examen-annexes .annexe-two input {
  width: 977px;
}

.patient-details .sexe-poids-taille-pc input,
.patient-details .poids-taille-poule-ta input {
  width: 200px;
}

.patient-details .psycho-class input {
  width: 346px;
}

.patient-details .obster-left-class input {
  width: 583px;
}

.patient-details .obster-right-class input {
  width: 335px;
}

.patient-details .ddr-terme input {
  width: 300px;
}

/*patient details cahier swiper */

.profile-cahier-swiper,
.profile-cahier-table-vaccin,
.profile-concultation,
.profile-hospitalisation,
.profile-antecedent {
  margin-top: 140px;
  padding-bottom: 30px;
}

.patient-details .sexe-date-heure,
.patient-details .date-poids-taille,
.patient-details .pre-fre,
.patient-details .transfert-motif {
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.patient-details .transfert-motif {
  padding-left: 10%;
}

.patient-details .sexe-date-heure input,
.patient-details .sexe-date-heure select,
.patient-details .date-poids-taille input,
.patient-details .pre-fre input {
  width: 250px;
}

.patient-details .motif input {
  width: 600px;
}

.patient-details .examen,
.patient-details .cathet,
.patient-details .recherche,
.patient-details .organes,
.patient-details .vitamine,
.patient-details .catheterisme,
.patient-details .ictere,
.patient-details .paleur,
.patient-details .hematome,
.patient-details .fente,
.patient-details .bec,
.patient-details .hanches,
.patient-details .imperformation,
.patient-details .yeux,
.patient-details .obstetricaux,
.patient-details .succion,
.patient-details .signe-moro,
.patient-details .tonus,
.patient-details .reaction,
.patient-details .conjonctivite,
.patient-details .erytheme,
.patient-details .globe {
  display: flex;
  justify-content: center;
}

/* antecedent style */
.Antecedent {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.antecedent-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1px;
}
.antecedent-hedear,
.addpatient-hedear,
.conculter-hedear {
  width: 100%;
  height: 96px;
  display: flex;
  background-color: rgba(79, 129, 255, 0.25);
  align-items: center;
  justify-content: space-between;
  padding-left: 59px;
  padding-right: 59px;
}

.antecedent-hedear input,
.addpatient-hedear input,
.conculter-hedear input,
.ajout-bebe input,
.enregistre-btn,
.malade-profile-header input {
  padding: 0;
  height: 39px;
  background-color: rgba(79, 129, 255, 1);
  font-size: 16px;
  line-height: 21.6px;
  color: #fff;
  cursor: pointer;
}

.malade-profile-header input {
  padding-right: 15px;
  padding-left: 15px;
}

.antecedent-hedear input:last-child,
.addpatient-hedear input:last-child,
.conculter-hedear input:last-child,
.enregistre-btn {
  width: 195px;
}

.antecedent-hedear input:first-child,
.addpatient-hedear input:first-child,
.conculter-hedear input:first-child {
  width: 114px;
}

.add-bebe {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: fit-content;
}
.Historique-container{
  width: 100%;
}
.add-bebe .table-patients {
  height: fit-content;
  width: 100%;
}

.ajout-bebe {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 112px;
  padding-right: 112px;
  height: fit-content;
}

.ajout-bebe h2,
.Maman-profile-title h2 {
  font-size: 28px;
  font-weight: 600;
  color: rgba(6, 14, 48);
  place-self: end;
}

.ajout-bebe input {
  width: 333px;
  height: 75px;
  font-size: 20px;
  line-height: 74px;
  margin-top: 40px;
  background-color: rgba(79, 129, 255, 1);
}

.antecedent-hedear h2,
.addpatient-hedear h2,
.conculter-hedear h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 33.6px;
  color: rgba(6, 14, 48);
}

.antecedent-container .antecedent-swipe {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  align-items: center;
}

/* doctor profile style */

.Doctor-profile {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.doctor-profile-container,
.rendezvous-container {
  width: 100%;
  height: calc(100vh - 80px);
  margin-top: 2px;
}

.doctor-profile-title,
.rendezvous-profile-title {
  padding-left: 50px;
  padding-top: 25px;
}

.doctor-profile-title h2,
.rendezvous-profile-title h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 33.6px;
  color: rgba(6, 14, 48);
  text-transform: capitalize;
}

.doctor-profile-title span,
.rendezvous-profile-title span,
.doctor-profile-header span,
.form-span span {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(106, 110, 131, 1);
}

.form-span span:hover {
  color: rgba(6, 14, 48);
  text-decoration: underline;
}

.doctor-profile-votre-photo {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 10px;
}

.doctor-profile-votre-photo h3 {
  font-weight: 600;
  line-height: 21.6px;
  font-size: 18px;
  color: rgba(6, 14, 48, 1);
}

.doctor-profile-votre-photo {
  padding-left: 154px;
  display: flex;
  flex-direction: column;
}

.doctor-profile-photo-changer-btn {
  display: flex;
  align-items: center;
  gap: 42px;
}

.doctor-profile-photo {
  width: 117px;
  height: 124px;
  border-radius: 50%;
  background-color: #d9d9d9;
}
.doctor-profile-photo img {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Set the border radius to 50% to create a circle */
  object-fit: cover; /* Maintain the image aspect ratio */
}

.doctor-profile-changer-btn {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.doctor-profile-changer-btn button,
.doctor-profile-changer-btn .changer-image-btn,
.patient-table-container .ajoute-nouveau-ne-item input,
.patient-table-container .search-item input,
.voir-btn {
  width: 141px;
  height: 39px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
}


.voir-btn-class{
  height: 25px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-color: #DA171B;
}
.voir-btn-class-red{
  background-color: #fff;
  height: 25px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  .voir-btn{
    color: red;
  }
}
.update-btn,.delete-btn{
  padding-left: 10px;
  display: flex;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  background-color: #DA171B;
  border-radius: 15px;
  width: 120px;
}
.Add-btn-class {
  height: 50px;
  width: 200px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-left: 10px;
  margin-top: 30px;
  padding-right: 10px;
  cursor: pointer;
  background-color: #DA171B;
}
.voir-btn,
.update-btn {
  width: fit-content;
  background: transparent;
}

.ajout-med-btn {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
}

.patient-table-container .search-item,
.patient-table-container .ajoute-nouveau-ne-item {
  margin-top: 20px;
}

.patient-table-container .ajoute-nouveau-ne-item input {
  width: 203px;
  border-radius: 10px;
}

.doctor-profile-changer-btn .changer-image-btn,
.patient-table-container .search-item input,
.patient-table-container .ajoute-nouveau-ne-item input,
.conculter-btn-class,
.voir-btn-class,
.ajout-med-btn,
.add-concultation-btn {
  background-color: #DA171B;;
}

.doctor-profile-changer-btn .changer-image-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-file {
  display: none;
}

.patient-table-container .class-search {
  width: 242px;
  height: 47px;
  border-radius: 10px;
  border: 1px solid rgba(6, 14, 48);
  background-color: transparent;
  padding-left: 1%;
  font-size: 12px;
  line-height: 14.4px;
  text-align: center;
}

.doctor-profile-changer-btn button:last-child {
  background-color: rgba(79, 129, 255, 0.4);
  color: rgba(79, 129, 255, 1);
}

.doctor-profile-changer-btn button:hover,
.doctor-profile-enregistrer-button:hover,
.rendezvous-enregistrer-button:hover,
.doctor-profile-changer-btn .changer-image-btn:hover,
.antecedent-hedear input:hover,
.addpatient-hedear input:hover,
.patient-table-container .ajoute-nouveau-ne-item input:hover {
  background-color: rgba(6, 14, 48, 1);
  transition: all 1s ease;
  color: #fff;
}

.doctor-profile-nom-hopital,
.rendezvous-nom-hopital {
  display: flex;
  align-items: center;
  gap: 43px;
  position: absolute;
  right: 157px;
  top: 129px;
}

.doctor-profile-nom-hopital h2,
.rendezvous-nom-hopital h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  color: rgba(6, 14, 48);
}

.doctor-profile-logo-hopital,
.rendezvous-logo-hopital {
  width: 116px;
  height: 121px;
  background-color: #d9d9d9;
  border-radius: 20px;
}

.Doctor-profile form {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  gap: 24px;
  padding-bottom: 20px;
  margin-left: 72px;
}

.doctor-profile-form-class,
.doctor-profile-form-class2 {
  width: 100%;
  display: flex;
  gap: 55px;
}

.doctor-profile-forme-left-item {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.doctor-profile-form-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.doctor-profile-form-item label {
  font-weight: 500;
  line-height: 14.4px;
  font-size: 12px;
  color: rgba(6, 14, 48);
  margin-left: 10px;
}

.doctor-profile-form-item input,
.doctor-profile-form-item select,
.doctor-profile-form-item textarea {
  width: 310px;
  height: 53px;
  border: 1px solid rgba(106, 110, 131, 1);
  border-radius: 10px;
  padding-left: 15px;
  color: rgba(106, 110, 131, 1);
  background-color: transparent;
  padding-right: 10px;
}

.doctor-profile-form-item textarea {
  width: 310px;
  height: 149px;
  padding-top: 10px;
}

.telephone-input {
  width: 310px;
  height: 53px;
  border: 1px solid rgba(106, 110, 131, 1);
  border-radius: 10px;
  padding-left: 15px;
  color: rgba(106, 110, 131, 1);
  background-color: transparent;
  display: flex;
  align-items: center;
}

.telephone-input span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14.4px;
  color: rgba(106, 110, 131, 1);
}

.telephone-input input {
  width: 90%;
  height: 100%;
  background-color: transparent;
  border: 0;
  padding-left: 5px;
}

.doctor-profile-form-item select,
.form-span span {
  cursor: pointer;
}

.form-span {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 30px;
}

.form-span-item {
  display: flex;
  align-items: center;
  gap: 11px;
}

.form-div-carre {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: rgba(217, 217, 217, 1);
}

.doctor-profile-enregistrer-class {
  margin-top: 80px;
  padding-bottom: 40px;
}

.doctor-profile-enregistrer-button,
.rendezvous-enregistrer-button {
  width: 141px;
  height: 39px;
  border-radius: 10px;
  background-color: rgba(79, 129, 255, 1);
  color: #fff;
  font-weight: 400;
  line-height: 14.4px;
  font-size: 12px;
  cursor: pointer;
}

/* add table vaccination + */

.editDate {
  width: 150px;
  background-color: transparent;
  border: 1px solid black;
}

.action-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  height: 100%;
}

.action-table button {
  width: 80%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #4f81ff;
}

.action-table button:hover {
  background-color: #4f81ff;
  border-radius: 5px;
  color: white;
  transition: all 1s ease;
}

.AddPatient {
  position: relative;
}

.add-tableau-vaccin1,
.add-tableau-vaccin2 {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  position: absolute;
  display: none;
}

.add-tableau-vaccin1 {
  margin-top: -19vh;
  justify-content: center;
}

.vaccination-table {
  width: 100%;
}

.add-tableau-vaccin2 {
  top: 593vh;
}

.add-tableau-vaccin1-container,
.add-tableau-vaccin2-container {
  background-color: #f5f5f5;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.add-tableau-vaccin1-container {
  gap: 0;
  padding: 10px;
}

.add-tableau-vaccin1-close-class,
.add-tableau-vaccin2-close-class {
  width: 100%;
  display: flex;
  justify-content: end;
}

.add-Vaccin-Table-active,
.add-Vaccin-Table-active2 {
  display: flex;
  transition: all 1s ease;
}

.vaccination-add-button.add-Vaccin-Table-active,
.vaccination-add-button.add-Vaccin-Table-active2 {
  justify-content: center;
  align-items: center;
}

.add-tableau-vaccin-ajouter-btn {
  width: 110%;
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.Ajoute-Vaccin-Btn {
  width: 191px;
  height: 46px;
  border-radius: 20px;
  background-color: rgba(79, 129, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  opacity: 0.8;
}

.Ajoute-Vaccin-Btn:hover,
.close-button:hover {
  opacity: 1;
  transition: all 1s ease;
}

.close-button {
  font-size: 28px;
  cursor: pointer;
  opacity: 0.8;
}

/*calendier style */
.Calendrier {
  position: relative;
}

.Calendrier .calendar-container {
  position: absolute;
  margin-top: 10px;
  left: 80px;
  width: calc(100% - 80px);
  background-color: #fff;
}

/* rendez-vous style */
.Rendezvous {
  width: 100%;
  height: 100vh;
}

.patient-rendez-vous{
  height: 89vh;
}

.patient-rendez-vous .rendezvous-container {
  width: 100%;
  height: 90vh;
}

.Rendezvous-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  gap: 24px;
  align-items: center;
}

.rendezvous-form-class {
  display: flex;
  gap: 49px;
}

.rendezvous-form-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rendezvous-form-item label {
  font-weight: 500;
  line-height: 14.4px;
  font-size: 12px;
  color: rgba(6, 14, 48);
  margin-left: 10px;
}

.rendezvous-form-item input,
.rendezvous-form-item select {
  width: 310px;
  height: 53px;
  border: 1px solid rgba(106, 110, 131, 1);
  border-radius: 10px;
  padding-left: 15px;
  color: rgba(106, 110, 131, 1);
  background-color: transparent;
  padding-right: 10px;
  font-size: 14px;
}

.telephone-input {
  width: 310px;
  height: 53px;
  border: 1px solid rgba(106, 110, 131, 1);
  border-radius: 10px;
  padding-left: 15px;
  color: rgba(106, 110, 131, 1);
  background-color: transparent;
  display: flex;
  align-items: center;
}

.telephone-input span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14.4px;
  color: rgba(106, 110, 131, 1);
}

.telephone-input input {
  width: 90%;
  height: 100%;
  background-color: transparent;
  border: 0;
  padding-left: 5px;
}

.rendezvous-form-item select {
  cursor: pointer;
}

.rendezvous-enregistrer-class {
  margin-top: 10px;
}

/* notification style */
.nav-notification {
  display: none;
}

.add-notification {
  display: block;
}

.Notification {
  position: absolute;
  top: 85px;
  right: 75px;
  margin-left: 10rem;
  width: 400px;
  height: 80vh;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background-color: white;
  box-shadow: 0 0 10px #060e30;
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  height: 74px;
  border-bottom: rgba(0, 0, 0, 0.15) solid 1px;
}

.notification-header h2 {
  font-weight: 500;
  margin-top: 8px;
  font-size: 22px;
  color: rgba(6, 14, 48);
}

.notification-container {
  overflow-y: scroll;
  margin-top: 2px;
}

.Notification-item {
  width: 100%;
  height: 100px;
  border-bottom: rgba(0, 0, 0, 0.15) solid 1px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 4%;
  padding-right: 4%;
}

.notification-pic {
  width: 75px;
  height: 75px;
  background-color: rgb(195, 195, 199);
  border-radius: 50%;
}

.notification-item-description h3 {
  font-weight: 700;
  font-size: 16px;
}

.notification-item-description h3 span {
  font-weight: 500;
  font-size: 16px;
  color: rgb(195, 195, 199);
}

.notification-item-description span {
  font-size: 12px;
  color: rgb(195, 195, 199);
}

/* conculter style */
.Conculter,
.Affichage-concultation,
.Patients-profile,
.Maman-profile,
.Rendezvous,
.Add-Hospitalisation,
.Affichage-Hospitalisation {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.conculter-container,
.affichage-concultation-container,
.patient-profile-container,
.maman-profile-container,
.rendezvous-container,
.Add-Hospitalisation-container,
.Affichage-Hospitalisation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
}

.Add-Hospitalisation-container,
.Affichage-Hospitalisation-container {
  align-items: center;
  height: calc(100vh - 80px);
}

.contenu-consultation {
  width: 100%;
  min-height: calc(100vh - 178px);
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: rgba(79, 129, 255, 0.1);
}

.conculter-container h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  color: rgba(6, 14, 48);
  margin-left: 78px;
}

.info-personal-concultation {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 53px;
  justify-content: space-between;
}

.info-personal-concultation h3,
.affichage-concultation-header h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  color: rgba(6, 14, 48);
}

.time-date-concultation {
  display: flex;
  gap: 64px;
}

.consultation-table {
  width: 100%;
  padding-left: 78px;
  padding-right: 78px;
  margin-top: 32px;
}

.consultation-table-container {
  width: 100%;
}

.consultation-table-item {
  display: flex;
  flex-direction: column;
}

.consultation-table-item-header,
.consultation-table-item-context-header,
.consultation-table-item-context-container {
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 21px;
  padding-right: 21px;
  border: 1px solid rgba(79, 129, 255, 1);
}

.consultation-table-item-context-container,
.consultation-table-item-context-header{
  gap: 10px;
  background-color: rgba(243, 243, 243, 1);
}
.consultation-table-item-header,
.bg-consultation {
  background-color: white;
}


.consultation-table-item-context-header form {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
}

.consultation-table-item-context-header,
.consultation-table-item-context-container,
.consul-ligne2 {
  border-top: 0;
}

.consultation-table-item-context-container {
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
}

.consultation-table-item-header h2,
.consultation-table-item-context h2 {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  color: rgba(6, 14, 48);
  margin-left: 0;
  cursor: pointer;
}

.down-icon,
.up-icon,
.close-icon {
  font-size: 40px;
  position: absolute;
  right: 21px;
  cursor: pointer;
}

.close-icon {
  font-size: 30px;
  right: 26px;
}

.up-icon {
  opacity: 0;
}

.consultation-table-item-context-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
}

.consultation-table-item-context-container:nth-child(2n) {
  background-color: rgb(243, 243, 243, 0.6);
}

.consultation-table-item-context-container textarea,
.consultation-table-item-context-container input {
  width: 100%;
  border-radius: 10px;
  padding: 1%;
  height: 258px;
  background-color: rgba(218, 218, 218, 1);
  font-weight: 700;
  font-size: 12px;
  line-height: 14.4px;
}

.consultation-table-item-context-container input {
  height: 56px;
}

.ordonance-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.ordonance-item h3 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(6, 14, 48);
  margin-top: 8px;
}

.ordonance-item select,
.ordonance-item input {
  width: 191px;
  height: 36px;
  font-size: 11px;
  line-height: 13.2px;
  font-weight: 700;
  color: rgba(174, 174, 174, 1);
  background-color: rgba(218, 218, 218, 1);
}

.quantite-item input,
.duree-item input {
  width: 99px;
  text-align: center;
}

.dose-item input {
  width: 251px;
  text-align: center;
}

.ordononce-medicament {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.medicament-description {
  display: flex;
}

.medicament-description h2:nth-child(1) {
  margin-left: 21px;
}

.medicament-description h2:nth-child(2) {
  position: absolute;
  left: 180px;
}

.medicament-description h2:nth-child(3) {
  position: absolute;
  left: 300px;
}
.medicament-description h2:nth-child(4) {
  position: absolute;
  left: 580px;
}

.action-table-med {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 8px;
}

.edit-btn-icon,
.delete-btn-icon {
  cursor: pointer;
  font-size: 25px;
}

.ord-med {
  height: 50px;
  padding: 0;
  padding-right: 22px;
}

.consultation-table-item-context {
  display: none;
}

.diagnostic,
.medicament,
.examen-ordononce {
  display: block;
}

.diagnostic .down-icon,
.medicament .down-icon,
.examen-ordononce .down-icon {
  opacity: 0;
}

.diagnostic .up-icon,
.medicament .up-icon,
.examen-ordononce .up-icon {
  opacity: 1;
  top: 5px;
}

.diagnostic h2,
.medicament h2,
.examen-ordononce h2 {
  margin-top: 15px;
}

.consultation-examen {
  height: 425px;
  background-color: rgb(243, 243, 243, 0.6);
}

/* affichage consultation style */
.affichage-concultation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 53px;
}

.affichage-concultation-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 73px;
  padding-left: 99px;
  padding-right: 121px;
}

/* patient profile */
.patient-profile-icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 55px;
  margin-top: 29px;
}

.left-icon {
  display: flex;
  gap: 21px;
}

.patient-profile-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}

.patient-profile-details h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  color: rgba(6, 14, 48);
}

.patient-profile-details-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4.75rem;
  padding-right: 4.75rem;
  margin-bottom: 50px;
  margin-top: 32px;
}

.patient-profile-details-container .about-patient {
  width: 75%;
  height: 287px;
  padding-top: 30px;
  padding-left: 20px;
  display: flex;
}

.patient-profile-details-container .about-patient-description h2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(6, 14, 48, 1);
}

.patient-profile-container .about-patient-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 160px;
  margin-top: 40px;
}

.patient-profile-details-container .patient-details-reducation {
  height: 287px;
  width: 20%;
}

.patient-profile-details-container .patient-details-reducation h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: rgba(6, 14, 48, 1);
  text-align: center;
  margin-top: 20px;
}

.affichage-concultation-container .consultation-table-item-context {
  display: flex;
  flex-direction: column;
}

.affichage-concultation-container .delete-btn-icon {
  display: none;
}

/* concultation table style */

.concultation-table {
  width: 90%;
  margin-top: 50vh;
  flex-direction: column;
  align-items: center;
}

.visite-table2 .concultation-table {
  margin-top: 0;
}

.table-concultation,
.table-Antecedent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-concultation-center {
  max-height: 696px;
  margin-top: 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-concultation-container {
  max-height: 646px;
  overflow-y: scroll;
}

.table-conclutation-container,
.Maman-profile-title {
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  align-items: center;
  border-bottom: 1px solid rgba(79, 129, 255, 1);
}

.table-conclutation-container h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: rgb(6, 14, 48);
  margin-left: 15px;
  margin-top: 10px;
}

.table-conclutation-search {
  width: 217px;
  background: rgba(218, 218, 218, 1);
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
}

.table-conclutation-search input {
  height: 33px;
  margin-left: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14.4px;
  color: rgba(106, 110, 131, 1);
}

.search-icon {
  font-size: 18px;
  margin-right: 10px;
  fill: rgba(106, 110, 131, 1);
}

.table-concultation-center {
  width: 100%;
  display: flex;
}

.table-concultation-center table {
  width: 100%;
}

.table-concultation-center table td {
  border: none;
}

.table-concultation-center table tr {
  height: 50px;
}

.table-concultation-ligne {
  position: relative;
}

.table-concultation-ligne .table-concultation-td-nom {
  position: absolute;
  left: 42px;
}

.table-concultation-ligne .table-concultation-td-date {
  position: absolute;
  left: 327px;
}

.table-concultation-ligne .table-concultation-td-time {
  position: absolute;
  left: 595px;
}

.table-concultation-ligne .table-concultation-td-search {
  position: absolute;
  right: 24px;
}

.table-concultation-ligne .table-concultation-td-button {
  position: absolute;
  right: 24px;
}

.table-concultation-tr {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: rgba(6, 14, 48);
}

.table-concultation-ligne {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(79, 129, 255, 1);
}

.concultation-table .conclutation-search {
  display: none;
}

.patient-profile-details .table-concultation {
  width: 100%;
  display: flex;
}

/* maman profile style */
.maman-profile-container {
  padding-bottom: 10rem;
}

.about-maman {
  width: 90%;
  height: 356px;
}

.about-maman-description h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 36px;
  margin-top: 34px;
  color: rgba(6, 14, 48);
}

.about-maman-cart {
  width: 100%;
  display: flex;
  gap: 200px;
  padding-left: 48px;
  margin-top: 26px;
}

.maman-cart-item span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: rgba(106, 110, 131, 1);
}

.maman-cart-item h3 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(6, 14, 48, 1);
}

.Maman-profile .patient-details-reducation {
  height: 356px;
}

.Maman-profile .table-concultation-ligne {
  border-bottom: 1px solid rgba(79, 129, 255, 1);
}

.Maman-profile .add-bebe .ajout-bebe input {
  width: fit-content;
  display: flex;
  font-size: 16px;
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
  height: fit-content;
}

.Maman-profile-title {
  border-bottom: none;
  padding-left: 112px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.profile-cahier-table-vaccin .vaccin-table-container h2 {
  display: none;
}

.profile-cahier-table-vaccin,
.profile-concultation,
.profile-hospitalisation,
.profile-antecedent {
  min-height: 300px;
  height: fit-content;
}

.profile-concultation .line-hl,
.profile-hospitalisation .line-hl,
.profile-antecedent .line-hl,
.profile-cahier-table-vaccin .line-hl {
  margin-bottom: 30px;
}

.profile-cahier-table-vaccin .line-hl {
  margin-bottom: 40px;
}

.patient-profile-container {
  padding-bottom: 10vh;
}
.profile-cahier-table-vaccin .vaccin-table-container {
  width: 100%;
}

.profile-cahier-table-vaccin {
  background-color: white;
}

.profile-cahier-table-vaccin .vaccin-table-container,
.profile-cahier-table-vaccin .visite-table-container {
  border-radius: 0;
  box-shadow: 0 transparent;
  background-color: #f0f0f0;
}

/*add hospitalisation style */
.Add-Hospitalisation-container .profile-hospitalisation,
.Affichage-Hospitalisation-container .profile-hospitalisation {
  margin-top: 40px;
}

.Add-Hospitalisation-container .profile-hospitalisation .table-concultation,
.Affichage-Hospitalisation-container
  .profile-hospitalisation
  .table-concultation,
.Patients-profile .profile-hospitalisation .table-concultation,
.Patients-profile .profile-concultation .table-concultation,
.Patients-profile .profile-antecedent .table-Antecedent,
.Maman-profile .profile-antecedent .table-Antecedent {
  width: 90%;
}

.Add-Hospitalisation .profile-hospitalisation,
.Affichage-Hospitalisation .profile-hospitalisation,
.Maman-profile .profile-hospitalisation {
  position: relative;
}

.add-concultation-btn {
  width: fit-content;
  font-size: 16px;
  padding-right: 21px;
  padding-left: 21px;
  position: absolute;
  right: 24px;
}

.Affichage-Hospitalisation .add-concultation-btn,
.Affichage-Hospitalisation .addpatient-hedear input,
.Maman-profile .profile-cahier-swiper-title input {
  display: none;
}

.Maman-profile .profile-hospitalisation .table-concultation {
  width: 90%;
}

.Affichage-Hospitalisation .addpatient-hedear {
  background-color: transparent;
  justify-content: center;
}

/* malade profile style */
.malade-profile-header {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 42px;
  padding-right: 60px;
  margin-top: 25px;
}

.malade-profile-left-header {
  display: flex;
  align-items: center;
  gap: 25px;
}

.malade-profle-image {
  width: 123px;
  height: 123px;
  border-radius: 50%;
  background-color: rgba(217, 217, 217, 1);
}

.malade-profile-name-id h2 {
  font-weight: 500;
  font-size: 25px;
  color: rgb(6, 14, 48);
  text-transform: uppercase;
}

.malade-profile-name-id span {
  font-weight: 500;
  font-size: 20px;
  color: rgb(106, 110, 131);
}

.malade-profile-information {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 30px;
}



.malade-profile-form-class {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.malade-profile-form-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.malade-profile-form-item label {
  font-weight: 500;
  line-height: 14.4px;
  font-size: 12px;
  color: rgba(6, 14, 48);
  margin-left: 10px;
}

.malade-profile-form-item input,
.malade-profile-form-item select,
.malade-profile-form-item textarea {
  width: 310px;
  height: 53px;
  border: 1px solid rgba(106, 110, 131, 1);
  border-radius: 10px;
  padding-left: 15px;
  color: rgba(106, 110, 131, 1);
  background-color: transparent;
  padding-right: 10px;
}

.malade-profile-form-item textarea {
  width: 511px;
  height: 440px;
  padding-top: 10px;
}

.malade-profile-form-item .telephone-input input {
  width: 90%;
  height: 100%;
  background-color: transparent;
  border: 0;
  padding-left: 5px;
}

.dashboard-table-container::-webkit-scrollbar,
.patient-details-examen::-webkit-scrollbar,
.table-patients::-webkit-scrollbar,
.notification-container::-webkit-scrollbar,
.table-enfant::-webkit-scrollbar,
.table-concultation-center::-webkit-scrollbar,
.table-concultation-container::-webkit-scrollbar {
  background-color: rgba(79, 129, 255, 0.25);
  border-radius: 2px;
  width: 5px;
}

.dashboard-table-container::-webkit-scrollbar-thumb,
.patient-details-examen::-webkit-slider-thumb,
.table-patients::-webkit-slider-thumb,
.notification-container::-webkit-scrollbar-thumb,
.table-enfant::-webkit-scrollbar-thumb,
.table-concultation-center::-webkit-scrollbar-thumb,
.table-concultation-container::-webkit-scrollbar-thumb {
  background-color: rgba(79, 129, 255, 1);
  width: 5px;
  border-radius: 2px;
}
.about-maman::-webkit-scrollbar,
.about-patient::-webkit-scrollbar,
.patient-details-reducation::-webkit-scrollbar,
.patient-details-center::-webkit-scrollbar,
.AddPatient::-webkit-scrollbar {
  width: 0;
}

.antecedent-container,
.AddPatient,
.conculter-container,
.affichage-concultation-container,
.Doctor-profile,
.patient-profile-container,
.maman-profile-container,
.rendezvous-container,
.Add-Hospitalisation-container,
.Affichage-Hospitalisation-container {
  z-index: 990;
  background-color: white;
}

.Login,
.patient-profile-container,
.maman-profile-container {
  z-index: 100;
  position: absolute;
  top: 0;
}

.doctor-profile-photo img {
  border-radius: 50%; /* Set the border radius to 50% to create a circle */
  object-fit: cover; /* Maintain the image aspect ratio */
}

.add-alert-class {
  position: absolute;
  top: 0;
  opacity: 0;
}

.add-alert-active {
  opacity: 1;
}
.next-back-row-table{
  font-size: 25px;
  margin: 10px;
  padding: 0;
}
.null-next-back-row-table{
  font-size: 10px;
  margin: 10px;
  padding: 0;
}
.pagination-buttons h1{
  font-size: larger;
  font-weight: 700;
  color: #060e30;
}
.pagination-buttons{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CircularProgress{
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: center;
  size: 30rem;
}
.CircularProgress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;}
.Main-app{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #F8F9FD;
  min-height: 100vh;
}
.Main-app h1{
  color: #DA171B;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
}
