@media (max-width: 768px){
    .patient-table-header{
        display: flex;
        flex-direction: column;
    }
}
.CircularProgress{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    align-content: center;
    size: 30rem;
}
.CircularProgress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.Main-app{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #F8F9FD;
    min-height: 100vh;
}
.Main-app h1{
    color: #DA171B;
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 1px;
}
.buttonsProducts{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}